// 后端服务器URL
// export const baseURL = "http://dev.cohmetrix.cn/api/"
export const baseURL = "https://cohmetrix.cn/api"

// 下载服务器URL
export const downLoadURL = `${baseURL}/resfile/`

export const downLoadURL2 = `${baseURL}/resfile/test`

// 支持上传文件类型
export const acceptFileType = [".docx", ".zip", ".txt"]

// 网页显示说明
export const infoData = [
    {
        "title": "网站说明",
        "content": "本站为Coh-Metrix中文站点，提供与Coh-Metrix相同的文本分析功能，仅可分析英文文本。"
    },
    {
        "title": "使用方法",
        "content": ["1. 登录（若无账号请先注册）", `2. 点击上传文件，支持${acceptFileType.join('、')}文件（文件名最好不要包含中文以及特殊符号）`, "3. 点击开始分析", "4. 等待分析完成之后可以下载分析结果", "5.可点击下面的链接查看分析指标的说明"],
        // 跳转到/readme
        "url": "readme"
    },
    {
        "title": "收费规则",
        "content": ["文本词数<1000，消耗篇数1", "文本词数1000-5000，消耗篇数2", "文本词数5000-10000，消耗篇数3", "（任务若分析失败，篇数将会返回账户中）"]
    },
    {
        "title": "联系我们",
        "content": `如有超过单篇10000词的文章，可联系站长wx或邮箱admin@cohmetrix.cn`
    }
]

// static前面不用加/
export const imgPath = "public/wechat.jpg"
export const imgSize = 200
export const readmeHtmlPath = "public/myreadme.html"


export const copyright = '© 2022 - 2025  赣ICP备18014059号-4'