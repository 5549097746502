<script setup>
import chargeDialog from "@/src/views/charge/charge.vue";
import {
  onUpdated,
  ref
} from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const router = useRouter();
let isLoginActivate = ref(false);
let isRegisterActivate = ref(false);
let userhasLogin = ref(false);
let username = ref("");
const store = useStore();
const getInfo = () => {
  store.dispatch("updateUserInfo");
};

const checkCurrentPage = () => {
  const localStorageUsername = localStorage.getItem("username");
  if (localStorageUsername) {
    username.value = localStorageUsername;
    userhasLogin.value = true;
    getInfo();
  } else {
    username.value = "";
    userhasLogin.value = false;
  }

  // 控制当前页面是否时注册or登录
  if (router.currentRoute._value.name === "login") {
    isLoginActivate.value = true;
    isRegisterActivate.value = false;
  } else if (router.currentRoute._value.name === "register") {
    isLoginActivate.value = false;
    isRegisterActivate.value = true;
  } else {
    isLoginActivate.value = false;
    isRegisterActivate.value = false;
  }
};

const routerPush = (path) => {
  router.push(path);
};

const logOut = () => {
  store.dispatch("logOut");
  userhasLogin.value = false;
  isLoginActivate.value = true;
  isRegisterActivate.value = false;
  routerPush("/login");
};

const toRegisterPage = () => {
  isLoginActivate.value = false;
  isRegisterActivate.value = true;
  routerPush("/register");
};
const charge = () => {
  store.commit("chargeStore/toggleVisible");
};

const closeChargeDialog = () => {
  store.commit("closeChargeDialog");
};
onUpdated(() => {
  checkCurrentPage();
});
checkCurrentPage();
</script>
<template>
  <div class="home">
    <div class="top">
      <div class="title" @click="routerPush('/')">Coh-Metrix</div>
      <div class="loginContent">
        <div class="loginInfo flexBox" v-if="store.state.isLogin">
          <span class="usernameText">{{ username }}</span>
          <div class="remainText">
            <span>剩余篇数：</span>
            <span
              :class="[store.state.remainCount == 0 ? 'redText' : 'greenText']"
              >{{ store.state.remainCount }}</span
            >
          </div>
          <div class="chargeBt" @click="charge()">充值</div>
          <div class="logoutBt" @click="logOut">登出</div>
        </div>
        <div v-else class="flexBox">
          <div
            class="loginBt"
            @click="logOut"
            :class="{ active: isLoginActivate }"
          >
            登录
          </div>
          <div
            class="registerBt"
            @click="toRegisterPage"
            :class="{ active: isRegisterActivate }"
          >
            注册
          </div>
        </div>
      </div>
    </div>
    <div class="mainWrap">
      <div class="content">
        <router-view />
      </div>
      <div class="footer">
        <div class="footerInfo footerLine">Powerd By KiWaTo </div>
        <div class="footerInfo">
          © 2022 - 2025
          <a href="http://www.beian.miit.gov.cn/">赣ICP备18014059号-4</a>
        </div>
      </div>
    </div>
  </div>

  <charge-dialog
    v-if="store.state.chargeStore.visible"
    :name="username"
    :count="store.state.remainCount"
  ></charge-dialog>

  <!-- <charge-info-dialog> </charge-info-dialog> -->
</template>


<style scoped>
.home {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.mainWrap {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.mainWrap::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  /**/
}
.mainWrap::-webkit-scrollbar-track {
  background: rgb(239, 239, 239);
  border-radius: 2px;
}
.mainWrap::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 10px;
}

.top {
  width: 100%;
  display: flex;
  position: fixed;
  top: 0;
  flex-direction: row;
  border-bottom: 1px solid #ddd;
  background: #fff;
  z-index: 999;
  height: 60px;
  box-sizing: border-box;
  overflow: auto;
}
.title {
  display: flex;
  align-items: center;
  padding-left: 55px;
  cursor: pointer;
  font-family: "SmileySans";
  font-size: 34px;
}
.loginContent {
  box-sizing: border-box;
  padding-right: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
}
.loginBt,
.registerBt,
.logoutBt {
  margin-right: 10px;
  cursor: pointer;
}
.loginBt:hover,
.registerBt:hover,
.logoutBt:hover {
  color: #409eff;
}

.usernameText {
  color: #409eff;
  font-size: 18px;
  font-weight: bold;
  margin-right: 20px;
}
.remainText {
  margin-right: 20px;
}

.redText {
  color: #f56c6c;
  font-weight: bold;
}
.greenText {
  font-weight: bold;
  color: #67c23a;
}
.logoutBt {
  margin-left: 20px;
}
.active {
  color: #409eff;
}
.content {
  flex: 1;
  display: flex;
  margin-top: 60px;
}

.chargeBt {
  cursor: pointer;
}

.chargeBt:hover {
  color: #409eff;
}

.footerInfo {
  text-align: center;
  line-height: 30px;
  color: #ddd;
  font-size: 12px;
  user-select: none;
}

.footerLine {
  border-top: 1px solid #ddd;
  margin: 0 60px;
}
@media screen and (max-width: 700px) {
  .content {
    flex-direction: column;
  }
}

.footerInfo a {
  color: #c2bfbf;
}
</style>