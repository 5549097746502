export const debounce = (interval, fn) => {
    let _timeout = null;
    return function () {
        if (_timeout) {
            clearTimeout(_timeout);
        }
        _timeout = setTimeout(() => {
            fn.apply(null, arguments);
            _timeout = null
        }, interval);
    };
};

export const throttle = (fn, delay) => {
    let valid = true
    return function () {
        if (!valid) {
            //休息时间 暂不接客
            return false
        }
        // 工作时间，执行函数并且在间隔期内把状态位设为无效
        valid = false
        fn.apply(null, arguments)
        setTimeout(() => {
            valid = true;
        }, delay)
    }
}
export const clearUserInfo = () => {
    localStorage.clear('username')
    localStorage.clear('token')
}


export const setSideBarState = (state) => {
    localStorage.setItem('isSideBarOpen', state)
}

export const getSideBarState = () => {
    return JSON.parse(localStorage.getItem('isSideBarOpen') ?? false)
}